export const WELCOME_PAGE_STANDARD_WELCOME =
  'Welcome, please enter a meeting code to join!';
export const WELCOME_PAGE_HOST_TERMINATED_MEETING =
  'The host has ended the meeting, please enter a meeting code to join!';
export const WELCOME_PAGE_BROWSER_NOT_SUPPORTED =
  'Sorry, your browser does not support Relay Meeting!';
export const WELCOME_PAGE_CANT_ACCESS_MEDIA =
  'Sorry, we were unable to identify a camera or a microphone on this system. Please make sure that the browser is permitted to access them!';
export const INVALID_MEETING_CODE =
  'Sorry, That is not a valid meeting code!';
export const MEETING_ENDED = 
  'Sorry, The meeting has ended!';

  export const AUDIO_VIDEO_DEVICE_UNAVAILABLE =
  'Sorry, No Audio Or Video Device available! Please try refreshing the page and try to rejoin once the devices are available';

export const FULLSCREEN_CONTROL_ENTER_FULLSCREEN = 'Enter fullscreen';
export const FULLSCREEN_CONTROL_EXIT_FULLSCREEN = 'Exit fullscreen';

export const MEETING_PAGE_INTERNET_BACK_UP_MSG =
  'Internet connection is back up, attempting to reconnect to the meeting';
export const MEETING_PAGE_LOST_INTERNET = 'Lost internet connection!';
export const MEETING_PAGE_INTERNET_RECONNECTING =
  'Please wait while we try to reconnect to your meeting';
export const MEETING_PAGE_EXIT_MEETING_ALERT_MSG =
  'A meeting is in progress. Are you sure you want to exit?';
export const MEETING_PAGE_LOADER_MSG = 'Please wait while we gather meeting information';
export const MEETING_PAGE_CONNECTING = 'Connecting, please wait ';
export const MEETING_PAGE_REQUEST_TO_JOIN = 'Request To Join';

export const BTN_YES_TEXT = 'YES';
export const BTN_NO_TEXT = 'NO';
export const BTN_CANCEL_TEXT = 'CANCEL';
export const BTN_BACK_TEXT = 'Back to Home';

export const MEDIA_SELECTOR_CHOOSE_MIC = 'Choose microphone:';
export const MEDIA_SELECTOR_CHOOSE_SPEAKER = 'Choose speaker:';
export const MEDIA_SELECTOR_CHOOSE_CAMERA = 'Choose camera:';
export const MEDIA_SELECTOR_JOIN_MEETING_WITH_MIC_MUTED =
  'Mute my mic when joining a meeting';
export const MEDIA_SELECTOR_JOIN_MEETING_WITH_CAMERA_TURNED_OFF =
  'Turn off my camera when joining a meeting';

export const SELECT_AUDIO_MICROPHONE = 'Microphone';
export const SELECT_AUDIO_SPEAKER = 'Speaker';
export const SELECT_CAMERA_CAMERA = 'Camera';
export const SELECT_CAMERA_PREVIEW = 'Preview';

export const DEVICES_SELECT_SELECT_AUDIO_DEVICES = 'Select audio devices';
export const DEVICES_SELECT_SELECT_SPEAKER = 'Select speaker';
export const DEVICES_SELECT_SELECT_MICROPHONE = 'Select microphone';
export const DEVICES_SELECT_SELECT_VIDEO_DEVICES = 'Select video devices';
export const DEVICES_SELECT_SELECT_CAMERA = 'Select camera';

export const MEDIA_CONTROL_TURN_MIC_ON = 'Turn mic ON or OFF';
export const MEDIA_CONTROL_TURN_MIC_OFF = 'Turn mic OFF';
export const MEDIA_CONTROL_TURN_CAM_ON = 'Turn camera ON';
export const MEDIA_CONTROL_TURN_CAM_OFF = 'Turn camera OFF';
export const MEDIA_CONTROL_SCREENSHARE_ON = 'Screen sharing ON';
export const MEDIA_CONTROL_SCREENSHARE_OFF = 'Screen sharing OFF';
export const MEDIA_CONTROL_EXIT_MEETING = 'Leave meeting';

export const RECORDING_CONSENT_MODAL_HEADER_TITLE = 'Recording Consent';
export const RECORDING_CONSENT_MODAL_PROMPT_BODY =
  'This meeting is being recorded. Continue?';
